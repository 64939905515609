<template>
  <b-modal
    id="agent-link-modal"
    ref="agent-link-modal"
    modal-class="modal-primary"
    title="ลิงก์สำหรับลงทะเบียนตัวแทน"
    hide-footer
    centered
  >
    <div ref="modalBody">
      <b-card-text>
        ส่งลิงก์ด้านล่างให้กับลูกค้าของคุณ
        เพื่อให้เค้ากรอกข้อมูลที่จำเป็นต่อการสมัครตัวแทน
        หรือผู้ดูแลจะกดเข้าลิงก์เพื่อกรอกข้อมูลแทนลูกค้าก็ได้เช่นกัน
      </b-card-text>

      <b-form-group>
        <b-input-group>
          <b-form-input
            :value="registrationLink"
            readonly
            @focus="$event.target.select()"
          />
          <b-input-group-append>
            <b-button
              variant="outline-primary"
              @click.prevent="onCopy"
            >
              <b-spinner
                v-if="isLoading"
                small
              />
              <feather-icon
                v-else
                icon="LinkIcon"
              />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>

      <b-card-text class="text-warning">
        ลิงก์จะสามารถสมัครได้ 1 ตัวแทนเท่านั้น
        เมื่อใช้แล้วจำเป็นต้องสร้างลิงก์ใหม่
      </b-card-text>

      <div class="d-flex justify-content-center">
        <b-button
          variant="outline-primary"
          class="mr-1"
          @click.prevent="hideModal"
        >
          ปิด
        </b-button>
        <b-button
          variant="primary"
          class="mr-1"
          @click.prevent="onClickNewLink"
        >
          สร้างลิงก์ใหม่
        </b-button>
        <b-button
          variant="primary"
          @click.prevent="onCopy"
        >
          คัดลอกลิงก์
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.agent.isGettingAgents,
    }),
    ...mapGetters(['registrationLink']),
  },
  methods: {
    ...mapActions(['getAgents', 'getRegistrationLink']),
    onClickNewLink() {
      this.getRegistrationLink()
    },
    hideModal() {
      this.$bvModal.hide('agent-link-modal')
    },
    onCopy() {
       this.$copyText(this.registrationLink,  this.$refs.modalBody).then( () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'คัดลอกแล้ว',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }, function (e) {
          alert('Can not copy')
          console.error(e)
        })
      
    },
  },
}
</script>
